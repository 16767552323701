/*
    PRESTASHOP EVENT
    updateCart	On the cart page, everytime something happens (change quantity, remove product and so on) the cart is reloaded by ajax call. After the cart is updated, this event is triggered.
    updatedAddressForm	In the address form, some input will trigger ajax calls to modify the form (like country change), after the form is updated, this event is triggered.
    updateDeliveryForm	During checkout, if the delivery address is modified, this event will be trigged.
    changedCheckoutStep	Each checkout step submission will fire this event.
    updateProductList	On every product list page (category, search results, pricedrop and so on), the list is updated via ajax calls if you change filters or sorting options. Each time the DOM is reloaded with new product list, this event is triggered.
    clickQuickView	If your theme handles it, this event will be trigged when you click on the quickview link.
    updateProduct	On the product page, selecting a new combination will reload the DOM via ajax calls. After the update, this event is fired.
    updatedProduct	On the product page, selecting a new combination will reload the DOM via ajax calls. After the update, this event is fired.
    handleError	This event is fired after a fail of POST request. Have the eventType as first parameter.
    updateFacets	On every product list page (category, search results, pricedrop and so on), the list is updated via ajax calls if you change filters or sorting options. Each time the facets is reloaded, this event is triggered.
    responsive update	While browser is resized, this event is fired with a mobile parameter.
*/

let Cart = {

    Init : () => {
        Cart.CheckCart();
        Cart.FocusReset();
        Cart.CartUpdate();
        Cart.Forms();
        Cart.Actions();
        Cart.OnUpdateProductList();
        Cart.CartIsOutdated();
    },

    CartIsOutdatedModal : () => {
        J('body').append(`
            <div id="qsupplier_cart_product_invalid_modal" class="qsupplier-cart-product-invalid-modal">

            <div class="cart-product-invalid-clode-modal">
                <i class="fas fa-times"></i>
            </div>
                <div class="cart-product-invalid-modal-content">
                    <p>Votre panier contenait des date de réception antérieure à la date du jour</p>
                    <!--<div class="cart-product-invalid-modal-btn">
                        <a href="/adresses">Ajouter, modifier ou choisir votre adresse de livraison</a>
                        <a href="/panier?action=show">Supprimer les produits de votre panier</a>
                    </div>-->
                </div>
            </div>
        `);
    },

    CartIsOutdated : () => {

        let uri     = window.location.href;
        let hash    = window.location.hash;

        if (hash && hash === '#cartoutdated') {
            var clean_uri = uri.substring(0, uri.indexOf("#"));
            window.history.replaceState({},document.title, clean_uri);
        }
    },

    // >> -- On re-controlle la disponibilité à la pagination de la liste produits --
    OnUpdateProductList : () => {

        if (typeof prestashop !== 'undefined') {
            prestashop.on('updateProductList', function (event) {
                Cart.CheckProductList();
            });
        }
    },

    // >> -- On affiche un message d'indisponibilité pour les produits -- 
    CheckProductListNotice : (storeId, idProduct, product) => {

        if (storeId && idProduct && product) {

            J('.qsuppliers-item').each( function(key, item){

                let id = parseInt(J(item).attr('data-id'));

                if (id === storeId) {

                    let availableProducts = J(item).attr('available-products');
                    let array = availableProducts.split(',');

                    if (array.indexOf((idProduct.toString())) === -1) {

                        if (J(product).find('.store-product-availability-notice').length) J(product).find('.store-product-availability-notice').remove();
                        J(product).append("<div class='store-product-availability-notice'>Ce produit n'est pas disponible pour cette boutique !</div>");
                    }
                }
            });
        }
    },

    // >> -- On controlle sur la liste des produits que les produits soient disponible a la vente pour la boutique selectionnée -- 
    CheckProductList : () => {

        let storeId = J('input[name="store_id"]').length ? parseInt(J('input[name="store_id"]').val()) : null;

        if (J('body').attr('id') === 'category' && storeId) {

            J('.js-product-miniature').each(function(index, product){
                let idProduct = J(product).attr('data-id-product');
                if (idProduct) Cart.CheckProductListNotice(storeId, idProduct, product);
            });
        }
    },

    // >> -- On controle sur la fiche produit que le produit est disponible à la vente pour la boutique selectionnée --
    CheckProductDetails : () => {

        let storeId = J('input[name="store_id"]').length ? parseInt(J('input[name="store_id"]').val()) : null;
        let idProduct = J('input[name="id_product"]').length ? parseInt(J('input[name="id_product"]').val()) : null;

        if (J('body').attr('id') === 'product' && storeId && idProduct) {

            J('.qsuppliers-item').each( function(index, item){

                let id = parseInt(J(item).attr('data-id'));

                if (id === storeId) {

                    let availableProducts = J(item).attr('available-products');
                    let array = availableProducts.split(',');

                    if (array.indexOf((idProduct.toString())) === -1) {
                        J('.product-quantity').html("Ce produit n'est pas disponible pour cette boutique ! ");
                    }
                }
            });
        }
    },

    FormatStoreHours : (hoursArray) => {

        let hoursday = hoursArray;
            hoursday = hoursday.replace(' ', '-');

        return hoursday.split('-');
    },

    HoursList : (info, hoursArray, timeslot, rangeLength, event, store=false) => {

        console.log('*** HOURSList ***')

        let moment  = require('moment');
        let now     = moment().format('YYYY-MM-DD');
        let nowHourTimestamp = parseInt((moment().format('h:mm')).replace(':', ''));

        let eventDateStr    = info.startStr;
        let timestampEvent  = parseInt(eventDateStr.replaceAll('-', ''));

        var datetime = new Date().toISOString();
            datetime = datetime.split('T')[0];
        var timestampNow = parseInt(datetime.replaceAll('-',''));

        let eventDay = event.startStr;

        let d           = new Date();
        let hourNow     = d.getHours();

        let min = parseInt((hoursArray[0]).toLowerCase().split('h')[0]);
        let max = parseInt((hoursArray[(hoursArray.length - 1)]).toLowerCase().split('h')[0]);

        J('.event-hours-not-available').remove();
        J('.ecalendar-modal-event-hours .calendar-modal-event-cancel').remove();

        let $container  = J('.stores-calendar-modal-event').children('.calendar-modal-event-hours');

        if (rangeLength > 1) {

            if (J('#modal_event_all').length) J('#modal_event_all').remove();

            if (timeslot === 'am') {

                if (!J('#modal_event_am').length) {
                    J(`
                    <div id="modal_event_am">
                        <h2>Créneaux horaires du matin</h2>
                        <div class="calendar-modal-event-hours"></div>
                        <br /><br />
                    </div>
                    `).insertAfter(J('#modal_event_title'));
                }
        
                $container = J('#modal_event_am').children('.calendar-modal-event-hours');
                J('.event-hours').remove();
                if ($container.children('.disabled-hours').length) $container.children('.disabled-hours').remove();
            }

            if (timeslot === 'pm') {

                if (!J('#modal_event_pm').length) {

                    J(`
                    <div id="modal_event_pm">
                        <h2>Créneaux horaires d'après midi</h2>
                        <div class="calendar-modal-event-hours"></div>
                    </div>`).insertAfter(J('#modal_event_am'));
                }
                $container = J('#modal_event_pm').children('.calendar-modal-event-hours');
                if ($container.children('.disabled-hours').length) $container.children('.disabled-hours').remove();
            }

        } else {

            J('.event-hours').remove();

            if (J('#modal_event_am').length) J('#modal_event_am').remove();
            if (J('#modal_event_pm').length) J('#modal_event_pm').remove();

            if (!J('#modal_event_all').length) {

                J(`<div id="modal_event_all">
                        <h2>Créneaux horaires disponibles</h2>
                        <div class="calendar-modal-event-hours"></div>
                </div>`).insertAfter(J('#modal_event_title'));
            }
            $container  = J('#modal_event_all').children('.calendar-modal-event-hours');
            if ($container.children('.disabled-hours').length) $container.children('.disabled-hours').remove();
        }

        // >> -- Si c'est aujourd'hui on décale jusqu'à l'heure actuelle + 3H.
        if (timestampEvent === timestampNow) {

            console.log('*** is today ***');

            let limit = 0;

            if (store && store.customer_delivery === '1') {

                let isFullLimit = false;

                // >> On contrôle des limites des créneaux du matin et aprés midi pour les livraisons
                if (store.limits && store.limits[eventDay]) {
                    let limitsStore = store.limits[eventDay];
                    if (timeslot === 'am' && (limitsStore.delivery_am_limit - limitsStore.am_count) <= 0) {
                        isFullLimit = true;
                    }
                    if (timeslot === 'pm' && (limitsStore.delivery_pm_limit - limitsStore.pm_count) <= 0) {
                        isFullLimit = true;
                    }
                }

                if (isFullLimit) {
                    $container.append(`<p class="disabled-hours">Plus de créneaux disponibles</p>`);
                } else {

                    let firstHour = (hoursArray[0]).replace('h', ':').replace('H', ':');
                    let firstHourTimestamp = parseInt(firstHour.replace(':', ''));

                    let lastHour = ((hoursArray[(hoursArray.length - 1)]).replace('h',':'));
                    let lastHourTimestamp = parseInt(lastHour.replace(':', ''));

                    if (lastHourTimestamp > (nowHourTimestamp + 300)) {

                        if ($container.children('.disabled-hours').length) $container.children('.disabled-hours').remove();

                        $container.append(`
                            <div class="event-hours">
                                ${firstHour+'-'+lastHour}
                            </div>
                        `);
                    } else {
                        if (!$container.children('.disabled-hours').length) $container.append(`<p class="disabled-hours">Plus de créneaux disponibles</p>`);
                    }

                    if (timeslot === 'pm') {
                        J('#modal_event_am').children('.calendar-modal-event-hours').html('<p class="disabled-hours">Plus de créneaux disponibles</p>')
                    }
                }
            } else {

                for (var i = min; i <= max; i++) {

                    let hour = i+':00', half = i+':30';

                    if (i > (hourNow + 2)) { // On affiche uniquement les heures à venir et pas celle passées +2h

                        $container.append('<div class="event-hours">'+hour+'</div>');

                        if (parseInt(half.split(':')[0]) < max) {
                            $container.append('<div class="event-hours">'+half+'</div>');
                        }
                    }

                    limit = i;

                    if (limit === max) {

                        if (J('.event-hours').length < 1) {
                            J('.stores-calendar-modal-event')
                                .children('.calendar-modal-event-hours')
                                .html("<div class='event-hours-not-available'>Pas d'horaire disponible</div>");
                        }
                    }
                }
            }

        } else {

            console.log('*** is other day ***');

            let index = 0;

            if (store && store.customer_delivery === '1') {

                let isFullLimit = false;

                // >> On contrôle des limites des créneaux du matin et aprés midi pour les livraisons
                if (store.limits && store.limits[eventDay]) {
                    let limitsStore = store.limits[eventDay];
                    if (timeslot === 'am' && (limitsStore.delivery_am_limit - limitsStore.am_count) <= 0) {
                        isFullLimit = true;
                    }
                    if (timeslot === 'pm' && (limitsStore.delivery_pm_limit - limitsStore.pm_count) <= 0) {
                        isFullLimit = true;
                    }
                }

                if (isFullLimit) {
                    $container.append(`<p class="disabled-hours">Plus de créneaux disponibles</p>`);
                } else {

                    $container.append(`
                        <div class="event-hours">
                            ${(hoursArray[0]).replace('h', ':').replace('H', ':')+'-'+((hoursArray[(hoursArray.length - 1)]).replace('h',':'))}
                        </div>
                    `);
                }
            } else {

                for (var i = min; i <= max; i++) {

                    let hour = i+':00', half = i+':30';

                    // >> -- Si c'est une livraison on ne décale pas les créneaux de 2H --
                    if (store && store.customer_delivery === '1') {

                        $container.append('<div class="event-hours">'+hour+'</div>');
                        if (parseInt(half.split(':')[0]) < max) {
                            $container.append('<div class="event-hours">'+half+'</div>');
                        }

                    } else {

                        // >> -- On décale de deux heures les récupérations ou livraisons pour la première heure d'ouverture de la boutique --
                        //if (index >= 2) {

                            $container.append('<div class="event-hours">'+hour+'</div>');
                            if (parseInt(half.split(':')[0]) < max) {
                                $container.append('<div class="event-hours">'+half+'</div>');
                            }
                        //}
                        // <<
                    }
                    // <<
                    index++;
                }
            }
        }
        // <<
    },

    DayHoursList : (dateStr, hoursArray, timeslot, rangeLength, store=false) => {

        console.log('*** DayHoursList ***');

        let moment  = require('moment');
        let now     = moment().format('YYYY-MM-DD');
        let nowHourTimestamp = parseInt((moment().format('h:mm')).replace(':', ''));

        let eventDateStr    = dateStr;
        let timestampEvent  = parseInt(eventDateStr.split('-').join(''));

        var datetime = new Date().toISOString();
            datetime = datetime.split('T')[0];

        var timestampNow = parseInt(datetime.split('-').join(''));

        let eventDay = dateStr;

        let d           = new Date();
        let hourNow     = d.getHours();
        let minuteNow     = d.getMinutes();

        let min = parseInt((hoursArray[0]).toLowerCase().split('h')[0]);
        let max = parseInt((hoursArray[(hoursArray.length - 1)]).toLowerCase().split('h')[0]);

        J('.event-hours-not-available').remove();

        let $container  = J('.stores-calendar-modal-event').children('.calendar-modal-event-hours');

        if (rangeLength > 1) {

            if (J('#modal_event_all').length) J('#modal_event_all').remove();

            if (timeslot === 'am') {

                if (!J('#modal_event_am').length) {
                    J(`
                    <div id="modal_event_am">
                        <h2>Créneaux horaires du matin</h2>
                        <div class="calendar-modal-event-hours"></div>
                        <br /><br />
                    </div>
                    `).insertAfter(J('#modal_event_title'));
                }
        
                $container = J('#modal_event_am').children('.calendar-modal-event-hours');
                J('.event-hours').remove();
                if ($container.children('.disabled-hours').length) $container.children('.disabled-hours').remove();
            }

            if (timeslot === 'pm') {

                if (!J('#modal_event_pm').length) {

                    J(`
                    <div id="modal_event_pm">
                        <h2>Créneaux horaires d'après midi</h2>
                        <div class="calendar-modal-event-hours"></div>
                    </div>`).insertAfter(J('#modal_event_am'));
                }
                $container = J('#modal_event_pm').children('.calendar-modal-event-hours');
                if ($container.children('.disabled-hours').length) $container.children('.disabled-hours').remove();
            }

        } else {

            J('.event-hours').remove();

            if (J('#modal_event_am').length) J('#modal_event_am').remove();
            if (J('#modal_event_pm').length) J('#modal_event_pm').remove();

            if (!J('#modal_event_all').length) {

                J(`<div id="modal_event_all">
                        <h2>Créneaux horaires disponibles</h2>
                        <div class="calendar-modal-event-hours"></div>
                </div>`).insertAfter(J('#modal_event_title'));
            }
            $container  = J('#modal_event_all').children('.calendar-modal-event-hours');
            if ($container.children('.disabled-hours').length) $container.children('.disabled-hours').remove();
        }

        // >> -- Si c'est aujourd'hui on décale jusqu'à l'heure actuelle + 3H.
        if (timestampEvent === timestampNow) {

            console.log('*** is today ***')

            let limit = 0;
            if ((hourNow >= (max - 3) && minuteNow > 30) || hourNow >= (max - 2)) {
                $container.html(`<p class="disabled-hours">Nous n'avons plus de créneaux pour aujourd'hui<span class="calendar-modal-event-btn"><button class="calendar-modal-event-cancel">Sélectionner une autre date</button></span></p>`);
            } else if (store && store.customer_delivery === '1') { // >> Livraison

                let isFullLimit = false;

                // >> On contrôle des limites des créneaux du matin et aprés midi pour les livraisons
                if (store.limits && store.limits[eventDay]) {
                    let limitsStore = store.limits[eventDay];
                    if (timeslot === 'am' && (limitsStore.delivery_am_limit - limitsStore.am_count) <= 0) {
                        isFullLimit = true;
                    }
                    if (timeslot === 'pm' && (limitsStore.delivery_pm_limit - limitsStore.pm_count) <= 0) {
                        isFullLimit = true;
                    }
                }

                let firstHour = (hoursArray[0]).replace('h', ':').replace('H', ':');
                let firstHourTimestamp = parseInt(firstHour.replace(':', ''));

                let lastHour = ((hoursArray[(hoursArray.length - 1)]).replace('h',':'));
                let lastHourTimestamp = parseInt(lastHour.replace(':', ''));

                // >> Ticket #8417
                // >> si l'heure actuelle + 3H est supérieure à la première heure du créneau on masque le créneau horaire..
                // >> Commentaire : Floriane DEZORD le 04/04/2023 à 10:03 
                if ((nowHourTimestamp + 300) >= firstHourTimestamp) {
                    isFullLimit = true;
                }
                // <<

                if (isFullLimit) {
                    $container.append(`<p class="disabled-hours">Plus de créneaux disponibles</p>`);
                } else {

                    if ($container.children('.disabled-hours').length) {
                        $container.children('.disabled-hours').remove();
                    }

                    $container.append(`
                        <div class="event-hours">
                            ${firstHour+'-'+lastHour}
                        </div>
                    `);

                    if (timeslot === 'pm') {

                        J('#modal_event_am')
                            .children('.calendar-modal-event-hours')
                            .html('<p class="disabled-hours">Plus de créneaux disponibles</p>')
                        ;
                    }
                }
            } else { // Retrait en boutique

                for (var i = min; i <= max; i++) {

                    let hour = i+':00', half = i+':30';

                    if (i > (hourNow + 2)) { // On affiche uniquement les heures à venir et pas celle passées + 2h

                        $container.append('<div class="event-hours">'+hour+'</div>');

                        if (parseInt(half.split(':')[0]) < max) {
                            $container.append('<div class="event-hours">'+half+'</div>');
                        }
                    }

                    // >> TODO #8417
                    // Floriane DEZORD le 20/04/2023 à 15:36 : Ils ont vu que : il était actuellement 10:11 mais le premier créneau horaire de possible était 14H alors que cela aurait dû être 13H30…
                    // Si l'heure de maintenant est égale a i mais que les minutes sont inférieure a 30 on affiche le créneau
                    if (i === (hourNow + 2) && minuteNow < 30) { 
                        if (parseInt(half.split(':')[0]) < max) {
                            $container.append('<div class="event-hours">'+half+'</div>');
                        }
                    }

                    limit = i;

                    if (limit === max) {

                        if (J('.event-hours').length < 1) {
                            J('.stores-calendar-modal-event')
                                .children('.calendar-modal-event-hours')
                                .html("<div class='event-hours-not-available'>Pas d'horaire disponible</div>");
                        }
                    }
                }
            }

        } else {



            let index = 0;

            if (store && store.customer_delivery === '1') {

                let isFullLimit = false;

                // >> On contrôle des limites des créneaux du matin et aprés midi pour les livraisons
                if (store.limits && store.limits[eventDay]) {
                    let limitsStore = store.limits[eventDay];
                    if (timeslot === 'am' && (limitsStore.delivery_am_limit - limitsStore.am_count) <= 0) {
                        isFullLimit = true;
                    }
                    if (timeslot === 'pm' && (limitsStore.delivery_pm_limit - limitsStore.pm_count) <= 0) {
                        isFullLimit = true;
                    }
                }

                if (isFullLimit) {
                    $container.append(`<p class="disabled-hours">Plus de créneaux disponibles</p>`);
                } else {

                    $container.append(`
                        <div class="event-hours">
                            ${(hoursArray[0]).replace('h', ':').replace('H', ':')+'-'+((hoursArray[(hoursArray.length - 1)]).replace('h',':'))}
                        </div>
                    `);
                }

                let amdelivery = store.timeslots_delivery_am.split(',');
                let pmdelivery = store.timeslots_delivery_pm.split(',');

                let dateClick = new Date(eventDay);
                let day = dateClick.getDay();
                if (day == 0) {
                    day = 6;
                } else {
                    day = day - 1;
                }

                if (amdelivery[day] == 0) {
                    J('#modal_event_am').children('.calendar-modal-event-hours').html('<p class="disabled-hours">Fermé</p>')
                } 
                if (pmdelivery[day] == 0) {
                    J('#modal_event_pm').children('.calendar-modal-event-hours').html('<p class="disabled-hours">Fermé</p>')
                }
            } else {

                for (var i = min; i <= max; i++) {

                    let hour = i+':00', half = i+':30';

                    // >> -- Si c'est une livraison on ne décale pas les créneaux de 2H --
                    if (store && store.customer_delivery === '1') {

                        $container.append('<div class="event-hours">'+hour+'</div>');
                        if (parseInt(half.split(':')[0]) < max) {
                            $container.append('<div class="event-hours">'+half+'</div>');
                        }

                    } else {

                        // >> -- On décale de 2 heures les récupérations ou livraisons pour la première heure d'ouverture de la boutique --
                        //if (index >= 2) {

                            $container.append('<div class="event-hours">'+hour+'</div>');
                            if (parseInt(half.split(':')[0]) < max) {
                                $container.append('<div class="event-hours">'+half+'</div>');
                            }
                        //}
                        // <<
                    }
                    // <<
                    index++;
                }
            }
        }
        // <<
    },

    ConvertDay : (day) => {

        if (day === 0) return 6;
        if (day === 1) return 0;
        if (day === 2) return 1;
        if (day === 3) return 2;
        if (day === 4) return 3;
        if (day === 5) return 4;
        if (day === 6) return 5;
    },

    Calendar : (store=null) => {

        let datetime    = false;
        let isDelivery  = false;
        let eventHours = false;

        // >> -- Récupération des heures de la boutique
        let hours = JSON.parse(store.hours);
        // >>

        // >> -- Si livraison, on remplace les heures boutique par les créneaux de livraison
        if (hours && store && store.customer_delivery === '1') {

            let timeslots = [], timeslotsAm = store.timeslots_am, timeslotsPm = store.timeslots_pm;

            if (timeslotsAm && timeslotsPm) {

                for (var i=0; i < hours.length; i++) {
                    timeslots.push([timeslotsAm+' '+timeslotsPm]);
                }
                hours = timeslots;
            }

            J('.stores-calendar-text').text('Choisissez votre jour et heure de livraison');

            isDelivery  = true;

        } else {
            J('.stores-calendar-text').text('Choisissez votre jour et heure de retrait');
        }
        // <<

        if (J('#stores_calendar_container_unavailable').length) {
            J('#stores_calendar_container_unavailable').remove();
            J('.stores-calendar-btn-confirm').show();
        }

        // >> -- On affiche l'agenda
        J('.qsuppliers-stores-calendar-container').show();

        var today = new Date().toISOString().slice(0,10);

        var calendarEl = document.getElementById('qsuppliers_calendar');
        var calendar = new FullCalendar.Calendar(calendarEl, {
            height: J('#stores_calendar_container').height(),
            showNonCurrentDates : true,
            weekNumbers : false,
            validRange: {
                start: today
            }
        });
        calendar.render();

        calendar.setOption('locale', 'fr');

        let closedDay = store.closed_day;
        if (isDelivery) closedDay = store.closed_day_delivery;

        if (closedDay) {
            let closedDayArray    = closedDay.split(',');

            if (closedDayArray) {
                for (var i=0; i < closedDayArray.length; i++) {

                    let isFull = false;
                    let title = isDelivery ? "PAS DE LIVRAISON" : "Fermé";

                    if (store.limits[closedDayArray[i]] ) {

                        let totalProducts           = parseInt(store.limits[closedDayArray[i]].total_products);
                        let maximumNumberOfTrays    = parseInt(store.limits[closedDayArray[i]].maximum_number_of_trays);

                        let diff = maximumNumberOfTrays - totalProducts;

                        if ( diff == 1 || maximumNumberOfTrays <= totalProducts) {
                            isFull = true;
                            title = 'COMPLET';
                        }
                    }
                    calendar.addEvent({
                        id      : closedDayArray[i],
                        title   : title,
                        start   : closedDayArray[i],
                        allDay  : true,
                        className : isFull ? 'fc-h-event-is-full-day' : 'fc-h-event-is-closed-day'
                    });
                }
            }
        }

        calendar.on('windowResize', function(arg){
            calendar.setOption('height', J('#stores_calendar_container').height());
        });
        // >> 

        calendar.on('eventClick', function(info) {

        });

        calendar.on('dateClick', function(info) {

            // >> -- On affiche la popin de créneaux horaires --
            J('.stores-calendar-modal-event').children('.calendar-modal-event-hours').html('');

            let date        = new Date(info.date);
            let day         = date.getDay();
            let events      = calendar.getEvents();
            let localeDate  = new Date(info.date).toLocaleDateString();

            let dateStr = info.dateStr

            if (events.length) {
                for (var i=0; i < events.length;i++) {
                    if (events[i].title != 'Fermé' && events[i].title != 'PAS DE LIVRAISON' && events[i].title != 'COMPLET') events[i].remove();
                }
            }

            J('.stores-calendar-modal-event').show();
            J('.stores-calendar-modal-event').children('.calendar-modal-event-title').text(localeDate);

            if (hours.length && hours[Cart.ConvertDay(day)].length) {

                let hoursRange = hours[Cart.ConvertDay(day)][0];
                    hoursRange = (hoursRange).split(' ');

                for (var i = 0; i < hoursRange.length; i++) {

                    let timeslot = i === 0 ? 'am' : 'pm';
                    let arrayHours = Cart.FormatStoreHours(hoursRange[i]);
                    Cart.DayHoursList(dateStr, arrayHours,timeslot, hoursRange.length, store);
                }
            }

            Jd.off('click', '.event-hours').on('click', '.event-hours', function(){

                eventHours = J(this).text();

                datetime = info.dateStr+'%'+(eventHours).split('-')[0];
                datetime = datetime.trim().replace(/\s/g, "");
                datetime = datetime.replace('%', 'T');
                
                calendar.addEvent({
                    title: eventHours+' le '+localeDate,
                    start: datetime,
                    end: datetime,
                    backgroundColor : 'rgba(3, 5, 25, 0.95)',
                    borderColor: 'rgba(3, 5, 25, 0.95)',
                });

                $('.fc-event-time').text('');

                datetime = datetime.replace('T', ' ');

                J('.stores-calendar-modal-event').hide();
                return false;
            });

            Jd.off('click', '.calendar-modal-event-cancel').on('click', '.calendar-modal-event-cancel', function(){
                J('.stores-calendar-modal-event').hide();
                datetime = false;
                eventHours = false;
                return false;
            })
        });

        Jd.off('click', '.stores-calendar-btn-cancel').on('click', '.stores-calendar-btn-cancel', function(){
            Cart.AjaxRemoveAllProduct();
            datetime = false;
            eventHours = false;
            return false;
        });

        Jd.off('click', '.stores-calendar-btn-confirm').on('click', '.stores-calendar-btn-confirm', function(){
            if (datetime) {
                Cart.AjaxAddDeliveryDate(datetime);
            }

            datetime = false;
            eventHours = false;
            return false;
        });
    },

    AjaxAddDeliveryDate : (datetime) => {

        let AjaxLink = J('input[name="qsuppliersAjaxLinkAddDeliveryDate"]').val();

        let Ajax = J.ajax({
            url: AjaxLink,
            type: 'POST',
            dataType: 'JSON',
            data : {
                delivery_date : datetime
            }
        })

        Ajax.done(function(result){
            if (result && result.response != 'success') {
                alert("Une erreur est survenue sur l'enregistrement de la date et heure de reception");
            }
            J('.qsuppliers-stores-calendar-container').hide();
        });

        Ajax.fail(function(error){
            //console.log(error);
        });

    },

    AjaxRemoveAllProduct : () => {

        let AjaxLink = J('input[name="qsuppliersAjaxLinkRemoveAllProducts"]').val();

        let Ajax = J.ajax({
            url: AjaxLink,
            type: 'POST',
            dataType: 'JSON'
        })

        Ajax.done(function(result){

            if (result && result.response === 'success') {
                window.location.reload();
            }
        });

        Ajax.fail(function(error){
            //console.log(error);
        });

    },

    Forms : () => {

        let map = false

        // >> -- Recherche du code postal --
        Jd.off('submit', 'form#qsuppliers_poscode_search_form').on('submit', 'form#qsuppliers_poscode_search_form', function(e){
            e.preventDefault();
            J('.qsuppliers-search-postcode-alert').hide();
            let postcode = J(this).children('#qsuppliers_poscode_search').val();
            Cart.IsValidPostcode(map, postcode);
            return;
        });
        // <<

        // >> -- Validation du choix de la boutique --
        Jd.off('submit', 'form#qsuppliers_store_choice_form').on('submit', 'form#qsuppliers_store_choice_form', function(e){

            e.preventDefault();

            let $that = J(this);

            if (J('input[name="qsuppliersAjaxLinkUpdateCart"]').length > 0) {

                let $storeChecked       = J('input[name="qsuppliers_choice"]:checked');
                let AjaxLink            = J('input[name="qsuppliersAjaxLinkUpdateCart"]').val();
                let StoreId             = $storeChecked.val();
                let Delivery            = 'no';
                let $deliveryContainer  = $storeChecked.parent().parent('label').children('.item-delivery-container');

                if ($deliveryContainer.children('.item-delivery').find('input[name="qsuppliers_delivery"]').length) {
                    if ($deliveryContainer.children('.item-delivery').find('input[name="qsuppliers_delivery"]').prop('checked')) {

                        let valueDelivery = $deliveryContainer.children('.item-delivery').find('input[name="qsuppliers_delivery"]').val();

                        if (valueDelivery === '1') {
                            Delivery = 'yes';
                        }
                    }
                }

                let datas = {
                    'id_product' : $that.attr('data-id-product'),
                    'id_product_attribute' : $that.attr('data-id-product-attribute'),
                    'id_customization' : $that.attr('data-id-customization'),
                    'id_store' : StoreId,
                    'delivery' : Delivery
                }

                let Ajax = J.ajax({
                    url: AjaxLink,
                    data : datas,
                    type: 'POST',
                    dataType: 'JSON'
                })

                Ajax.done(function(result){
                    if (result && result.response === 'success') {
                        J('.qsuppliers-stores-choice-container').hide();
                    }

                    Cart.CheckCart();
                });

                Ajax.fail(function(error){
                    //console.log(error);
                });
            }
        });
        // <<

        Jd.off('change', 'input[name="qsuppliers_delivery"]').on('change', 'input[name="qsuppliers_delivery"]', function(){

            $('input[name="qsuppliers_choice"]').prop('checked', false).removeAttr('checked');
            $('input[name="qsuppliers_delivery"]').prop('checked', false).removeAttr('checked');
            $(this).prop('checked', true).attr('checked', 'checked');

            let $label = J(this).parent().parent().parent().parent('label');
            $label.children('.item-field').find('input[name="qsuppliers_choice"]').prop('checked', true).attr('checked', 'checked');
        });

        Jd.off('change', 'input[name="qsuppliers_choice"]').on('change', 'input[name="qsuppliers_choice"]', function(){
            let $label = J(this).parent().parent('label');

            $('input[name="qsuppliers_delivery"]').prop('checked', false).removeAttr('checked');

            if ($label.children('.item-delivery-container').children('.item-delivery').length && $label.children('.item-delivery-container').children('.item-delivery').is(':visible')){
                $label.children('.item-delivery-container').children('.item-delivery').find('input[name="qsuppliers_delivery"]').prop('checked', true).attr('checked', 'checked');
            }
            if ($label.children('.item-delivery-container').children('.item-to-go').length && $label.children('.item-delivery-container').children('.item-to-go').is(':visible')){
                $label.children('.item-delivery-container').children('.item-to-go').find('input[name="qsuppliers_delivery"]').prop('checked', true).attr('checked', 'checked');
            }
        });
    },

    IsValidPostcode : (map, postcode) => {

        J('.qsuppliers-search-postcode-alert').hide();

        if (postcode.length >= 5 && Cart.CheckPostcode(postcode)) {

            J('.qsuppliers-stores-search-container, .qsuppliers-search-container').hide();
            J('.qsuppliers-stores-choice-container').show();

            if (map) {
                map.remove();
                map = false;
            }
            require('./Map').ChoiceStore( postcode, function(result){

                map = result;
                
            });
        } else {
            J('.qsuppliers-search-postcode-alert').show();
            return;
        }

        if (!J('.qsuppliers-item:visible').length) {
            J('.qsuppliers-label').hide();
            J('form#qsuppliers_store_choice_form').children('button[type="submit"]').hide();
            J('.qsuppliers-no-shop').show();
        } else {
            J('.qsuppliers-item:visible').first().click();
            J('.qsuppliers-item:visible').first().children('.item-delivery-container').children('.item-to-go').find('input[type="radio"][value="0"]').attr('checked', 'checked');
        }
    },

    CheckPostcode : (postcode) => {

        var regex = /^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/;

        if(regex.test(postcode) == false){
            return;
        }
        return true;
    },

    CheckCart : () => {

        let AjaxLink = J('input[name="qsuppliersAjaxLinkCheckCart"]').val();

        let Ajax = J.ajax({
            url: AjaxLink,
            type: 'POST',
            dataType: 'JSON'
        })

        Ajax.done(function(result){

            J('.qsuppliers-loader').hide();

            if (result && result.response === 'success') {
                if (result.data.length) {
                    window.location.reload();
                    return;
                }

                // >> -- On affiche la boutique choisi su rla page --
                if (result.store && J('body').attr('id') != 'cart' && J('body').attr('id') != 'checkout') {
                    J('#header').prepend(`
                        <div id="qsupplier_user_store">
                            <i class="fas fa-store"></i> <span>${result.store.name}</span> - <span>${result.store.address1} ${result.store.postcode} ${result.store.city}</span>
                            <input type="hidden" name="store_id" value="${result.store.id_store}"/>
                        </div>
                        `
                    );

                    Cart.CheckProductList();
                    Cart.CheckProductDetails();
                }
                // <<

                if (result.store) {
                    if (!result.store.delivery_date) {
                        Cart.Calendar(result.store);
                    }
                }
            } else {
                alert('une erreur est survenue !');
            }
        });

        Ajax.fail(function(error){
            //console.log(error);
        });
    },

    CheckproductAvailability: (idProduct) => { // >> -- On check si le produit est disponible dans cette boutique. --

        J('.qsuppliers-item').attr('is-available', '1').show();
        J('form#qsuppliers_store_choice_form').children('button[type="submit"]').show();
        J('.qsuppliers-label').show();
        J('.qsuppliers-no-shop').hide();

        J('.qsuppliers-item').each( function(index, item){

            let availableProducts = J(item).attr('available-products');
            let array = availableProducts.split(',');
            
            if (array.indexOf((idProduct.toString())) === -1) {
                J(item).attr('is-available', '0').hide();
            }
        });
    },

    CartUpdate: () => { // >> -- Quand le panier est mis à jour --

        if (typeof prestashop !== 'undefined') {

            prestashop.on('updateCart', function (event) {

                if (event.resp && event.resp.quantity && event.resp.id_product) {

                    let respQuantity    = event.resp.quantity;
                    let idProduct       = event.resp.id_product;
                    let idAttribute     = event.resp.id_product_attribute ? event.resp.id_product_attribute : 0 ;

                    if (J('.hook-suppliers-choices-cart[product-id="'+idProduct+'"]').length) {
            
                        let current = parseInt(J('.hook-suppliers-choices-cart[product-id="'+idProduct+'"]').attr('day-trays'));
                        let maxLimit = parseInt(J('.hook-suppliers-choices-cart[product-id="'+idProduct+'"]').attr('max-trays'));

                        if (current + respQuantity > maxLimit) {
                            alert("La limite de commande est atteinte, la boutique ne permet pas de passer d'autre commande, désolé !");
                            let token       = prestashop.static_token;
                            let actionURL   = '/index.php';
                            let query       = 'controller=cart&add=1&action=update&ajax=true&qty=1&op=down&token='+token+'&id_product='+ idProduct +'&ipa='+ idAttribute;
                            $.post(actionURL, query, null, 'json').then(function (resp) {
                                //console.log(resp);
                            }).fail(function (resp) {
                                //console.log(resp);
                            });
                            window.location.reload();
                            return;
                        }
                    }
                }
                if (J('body').attr('id') != 'cart' && event.reason.linkAction === 'add-to-cart' && event.reason.linkAction != 'delete-form-cart') {

                    J('.qsuppliers-loader').show();

                    let productId = event.reason.idProduct;
                    let attributeId = event.reason.idProductAttribute;
                    let customizationId = event.reason.idCustomization;

                    Cart.CheckproductAvailability(productId);

                    J('.qsuppliers-search-label-alert').hide();

                    let AjaxLink = J('input[name="qsuppliersAjaxLinkUpdateStore"]').val();

                    let Ajax = J.ajax({
                        url: AjaxLink,
                        data : {
                            'id_product' : productId,
                            'id_product_attribute' : attributeId,
                            'id_customization' : customizationId
                        },
                        type: 'POST',
                        dataType: 'JSON'
                    });
    
                    Ajax.done(function(result){

                        if (result && result.response === 'no-product') {

                            Cart.CheckProduct(productId, attributeId, function(result){
        
                                if (result) {
        
                                    if (result.viewpopin) {
        
                                        if (result.response === 'success') {
        
                                            if  (result.data) {
                                            } else {
        
                                                J('.qsuppliers-search-label-alert').hide();
                                                J('.qsuppliers-stores-search-container').show();
        
                                                let token = J('input[name="qsuppliersToken"]').val();
        
                                                J('.qsuppliers-close').attr({
                                                    'href' : '/panier?delete=1&id_product='+productId+'&id_product_attribute='+attributeId+'&token='+token,
                                                    'data-link-action' : 'delete-from-cart',
                                                    'data-id-product' : productId,
                                                    'data-id-product-attribute' : attributeId,
                                                    'data-id-customization' : customizationId
                                                }).addClass('remove-from-cart');
        
                                                J('form#qsuppliers_store_choice_form').attr({
                                                    'data-id-product' : productId,
                                                    'data-id-product-attribute' : attributeId,
                                                    'data-id-customization' : customizationId
                                                }); 
                                            }
                                            
                                        } else {
                                            alert('une erreur est survenue !');
                                        }
                                    }
                                }
                                J('.qsuppliers-loader').hide();
                            });

                        } else {

                            //alert('livraison ou a emporter ?');
                        }
                    });
    
                    Ajax.fail(function(error){
                        //console.log(error);
                    });

                    J('.qsuppliers-loader').hide();
                }
            });
        }
    },

    CheckProduct : (productId, attributeId, callback) => {  

        let AjaxLink = J('input[name="qsuppliersAjaxLinkCheckProduct"]').val();

        let Ajax = J.ajax({
            url: AjaxLink,
            data: {
                id_product : productId,
                id_product_attribute : attributeId
            },
            type: 'POST',
            dataType: 'JSON'
        })

        Ajax.done(function(result){
            J('.qsuppliers-loader').hide();
            return callback(result);
        });

        Ajax.fail(function(error){
            //console.log(error);
            return callback(error);
        });
    },

    FocusReset : () => {
        window.addEventListener('focus', function(event) {
            event.stopImmediatePropagation();
        }, true);
    },

    Actions : () => {

        Jd.off('click', '.qsuppliers-postcode-required-btn').on('click', '.qsuppliers-postcode-required-btn', function(e){
            e.preventDefault();
            J('.qsuppliers-stores-search-container').show();
            return;
        });

        Jd.off('click', '.qsuppliers-close').on('click', '.qsuppliers-close', function(e){
            e.preventDefault();
            J('.qsuppliers-stores-search-container').hide();
            J('.qsuppliers-stores-choice-container').hide();

            if (J('#blockcart-modal').length > 0) {
                J('#blockcart-modal').find('.modal-header').children('button').trigger('click');
            }
            Cart.CheckCart();
            return;
        });
    }
}
module.exports = Cart;