import '../scss/qsuppliers_front.scss';
"use-strict"
let jQuery = require('jquery');

window.J = jQuery;
window.Jd = J(document);

Jd.ready(function(){
    require('./modules/Cart').Init();
    require('./modules/Checkout').Init();
    require('./modules/Address').Init();
});