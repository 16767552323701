let Address = {

    Init : () => {
        //Address.AddressAutoComplete();
        Address.CheckPostcodeDeliveryActions();
        Address.CheckPostcodeDelivery();
    },

    AddressAutoComplete : () => { // >> UNUSED

        let $addressField   = J('[name="address1"]');
        let $postcodeField  = J('[name="postcode"]');
        let $cityField      = J('[name="city"]');
        let $countryField   = J('[name="id_country"]');

        if ($addressField.length && $postcodeField.length && $cityField.length && $countryField.length) {

            // >> -- Au chargement on ajoute le block d'autocomplétion --
            Address.AutocompleteOnLoad();
            // <<

            // >> -- Au changement de l'adresse recherchée, on fait appel à l'Api adresse.data.gouv.fr --
            $addressField.off('keyup').on('keyup', function(e){

                let value           = J(this).val();
                let frequencies     = [6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48, 51, 54, 57, 60, 63];
                let valueLength     = value.length;

                if (frequencies.indexOf(valueLength) != -1) Address.Search();

                if (valueLength < 1) Address.AutocompleteReset(10);
            });
            // <<

            // >> -- Au choix de l'adresse, on rempli le formulaire avec les attributs de l'item address --
            Jd.off('click', '.address-autocomplete-item').on('click', '.address-autocomplete-item', function(){

                let $that = J(this);

                $addressField.val($that.attr('name'));
                $postcodeField.val($that.attr('postcode'));
                $cityField.val($that.attr('city'));

                // >> -- On re initialise le block autocomplétion --
                Address.AutocompleteReset(200);
                // <<
            });
            // <<

            // >> -- Quand on perd le focus sur le champ address1, on reset le block autocompletion --
            J('input[name="address1"]').on('focusout', function(){
                // >> -- On re initialise le block autocomplétion --
                Address.AutocompleteReset(500);
                // <<
            });
            // <<
        }
    },

    CheckPostcodeDeliveryModal : () => {

        $('body').append(`
            <div id="qsupplier_poscode_invalid_modal" class="qsupplier-poscode-invalid-modal">

            <div class="poscode-invalid-clode-modal">
                <i class="fas fa-times"></i>
            </div>
                <div class="poscode-invalid-modal-content">
                    <p>Le code postal de votre adresse de livraison ne concorde pas avec les adresses desservies par le magasin choisi, <br>modifiez votre adresse ou vider votre panier pour choisir un autre magasin</p>
                    <div class="poscode-invalid-modal-btn">
                        <a href="/adresses">Ajouter, modifier ou choisir votre adresse de livraison</a>
                        <a href="/panier?action=show">Supprimer les produits de votre panier</a>
                        <a href="#" class="-poscode-invalid-clode-modal">Fermer la fenêtre et changer votre adresse de livraison</a>
                    </div>
                </div>
            </div>
        `);
    },

    CheckPostcodeDeliveryActions : () => {

        Jd.off('click', '.poscode-invalid-clode-modal, .-poscode-invalid-clode-modal').on('click', '.poscode-invalid-clode-modal, .-poscode-invalid-clode-modal', function(e){
            e.preventDefault();
            J('#qsupplier_poscode_invalid_modal').remove();
        });

        Jd.off('change', 'input[name="id_address_delivery"]').on('change', 'input[name="id_address_delivery"]', function(){
            Address.CheckPostcodeDelivery();
        });
    },

    CheckPostcodeDelivery : () => {

        if (J('body').attr('id') === 'checkout') {

            let poscodeAvailable    = J('input[name="store_available_postcode"]').length ? J('input[name="store_available_postcode"]').val() : null;
            let deliveryPostcode    = J('input[name="id_address_delivery"]:checked').length ? J('input[name="id_address_delivery"]:checked').attr('postcode') : null;
            let storeDelivery       = J('input[name="store_delivery"]').length ? parseInt(J('input[name="store_delivery"]').val()) : 0;

            if (poscodeAvailable && deliveryPostcode && storeDelivery) {

                let arrayPostcode = poscodeAvailable.split(',');

                if (arrayPostcode.indexOf((deliveryPostcode.toString())) === -1) {
                    J('button[type="submit"][name="confirm-addresses"]').attr('disabled', 'disabled').hide();
                    Address.CheckPostcodeDeliveryModal();
                    $('#checkout-addresses-step').trigger('click');
                } else {
                    
                    J('button[type="submit"][name="confirm-addresses"]').removeAttr('disabled').show();
                }
            }

            let deliveryState = $('input[name="delivery_state"]').val();
            if (deliveryState  && !deliveryPostcode) {
                $('#checkout-addresses-step').trigger('click');
            }

        }
    },

    AutocompleteOnLoad: () => {

        // >> -- Au chargement on ajoute le block d'autocomplétion --
        J('[name="address1"]').parent().prepend("<small>Choix d'adresse par autocomplétion obligatoire.</small>");
        J('[name="address1"]').parent().append(
            `<div id="address_autocomplete" class="address-autocomplete">
                <div id="address_autocomplete_list" class="address-autocomplete-list"></div>
                <div id="address_autocomplete_loader" class="address-autocomplete-loader"><img src="/modules/qsuppliers/loader.gif"/></div>
            </div>`
        );
        // <<
        
        // >> -- On désactive les champs postcode et city --
        J('[name="postcode"]').attr('readonly', 'readonly');
        J('[name="city"]').attr('readonly', 'readonly');
        // <<

    },

    AutocompleteReset : (timeout) => {

        setTimeout(function(){
            J('#address_autocomplete_loader').show();
            J('#address_autocomplete').hide();
            J('#address_autocomplete_list').html('');
        },timeout);
    },

    Search : () => {

        // Api : https://adresse.data.gouv.fr/api-doc/adresse
        // Exemple : "https://api-adresse.data.gouv.fr/search/?q=8+bd+du+port"

        let $addressField   = J('[name="address1"]');
        let baseUrl         = 'https://api-adresse.data.gouv.fr/search/';
        let q               = '?q='+$addressField.val();
        let params          = '&type=housenumber&autocomplete=1';

        let Ajax = J.ajax({
            url : baseUrl+q+params,
            method : 'GET',
            dataType : 'JSON'
        });

        Ajax.done(function(result){

            J('#address_autocomplete_list').html('');
            J('#address_autocomplete_loader').show();

            if (result.features) {

                J('#address_autocomplete').show();
                J('#address_autocomplete_loader').hide();

                let features = result.features;

                for (var i=0; i < features.length; i++) {

                    if (features[i]) {

                        J('#address_autocomplete_list').append(
                            `<div 
                                postcode="${features[i].properties.postcode}" 
                                city="${features[i].properties.city}" 
                                street="${features[i].properties.street}" 
                                streetnumber="${features[i].properties.housenumber}" 
                                name="${features[i].properties.name}"
                                class="address-autocomplete-item"
                            >
                                ${features[i].properties.label}
                            </div>`
                        );
                    }
                }
            }
        });

        Ajax.fail(function(error){
            //console.log(error);
        });
    }
}
module.exports = Address;